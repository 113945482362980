/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import HomePage from "views/examples/HomePage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import Nosotros from "views/examples/Nosotros";
import Productos from "views/examples/Productos";
import Servicios from "views/examples/Servicios";
import Eventos from "views/examples/Eventos";
import YamahaPage from "views/examples/YamahaPage";
import SsendaPage from "views/examples/SsendaPage";
import EbotPage from "views/examples/EbotPage";
import MakibaPage from "views/examples/MakibaPage";
import SsendaBiciPage from "views/examples/SsendaBiciPage";
import AccesoriosPage from "views/examples/AccesoriosPage";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/nosotros-page"
        render={(props) => <Nosotros {...props} />}
      />
      <Route
        path="/productos-page"
        render={(props) => <Productos {...props} />}
      />
      <Route
        path="/yamaha-page"
        render={(props) => <YamahaPage {...props} />}
      />
      <Route
        path="/ssenda-page"
        render={(props) => <SsendaPage {...props} />}
      />
      <Route
        path="/ssendaBici-page"
        render={(props) => <SsendaBiciPage {...props} />}
      />
      <Route
        path="/accesorios-page"
        render={(props) => <AccesoriosPage {...props} />}
      />
      <Route
        path="/ebot-page"
        render={(props) => <EbotPage {...props} />}
      />
      <Route
        path="/makiba-page"
        render={(props) => <MakibaPage {...props} />}
      />
      <Route
        path="/servicios-page"
        render={(props) => <Servicios {...props} />}
      />
      <Route
        path="/eventos-page"
        render={(props) => <Eventos {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
