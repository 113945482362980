
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import EbotHeader from "components/Headers/EbotHeader";
import MEFooter from "components/Footers/MEFooter";
import FabRS from "components/Fab/FabRS";
import EbotCarousel from "./EbotCarousel";
import AccesoriosHeader from "components/Headers/AccesoriosHeader";

function AccesoriosPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("accesorios-page");
    return function cleanup() {
      document.body.classList.remove("accesorios-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <AccesoriosHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <h2 className="title">ACCESORIOS</h2>
            <Row>
              <Col md="4">
                <img
                  alt="..."
                  src={require("../../assets/img/accesorios/LS2Helmets.png").default}
                  width="200" height="200"
                />
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  src={require("../../assets/img/accesorios/YAMALUBE.png").default}
                  width="200" height="200"
                />
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  src={require("../../assets/img/accesorios/YOHE.png").default}
                  width="200" height="200"
                />
              </Col>

            </Row>
            <Row>
              <Col md="6">
                <img
                  alt="..."
                  src={require("../../assets/img/accesorios/yamalube-logo.jpg").default}
                  width="200" height="200"
                />
              </Col>
              <Col md="6">
                <img
                  alt="..."
                  src={require("../../assets/img/accesorios/pngegg.png").default}
                  width="200" height="200"
                />
              </Col>

            </Row>
          </Container>
          <FabRS />
        </div>
        {/* <div style={{
          backgroundImage:
            "url(" + require("assets/img/Fondo.png").default + ")",
        }} className="section section-dark text-center">
          <Container>
            <h2 className="title">Contáctanos</h2>
            <Row>
              <Col md="6">
              <img
                alt="..."
                src={require("../../assets/img/Yamaha/YamahaRecurso 1@4x.png").default}
                width="500" height="500"
              />
              </Col>
              <SectionCarousel />

            </Row>
          </Container>
          <FabRS />
        </div> */}
        <MEFooter />
      </div>
    </>
  );
}

export default AccesoriosPage;
