/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import FabRS from "components/Fab/FabRS";
import MEFooter from "components/Footers/MEFooter";
import ServicioTecnicoHeader from "components/Headers/ServicioTecnicoHeader";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Servicios() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("servicios-page");
    return function cleanup() {
      document.body.classList.remove("servicios-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ServicioTecnicoHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <h2 className="title">Servicio Técnico</h2>
            <Row>
              <Col md="12">
                <h5 className="description">
                  Para agendar una cita
                </h5>
                <a
                  data-placement="bottom"
                  href="tel:+51993031505"
                >
                  <h5 className="description">
                    +51993031505
                  </h5>
                </a>
              </Col>
            </Row>
          </Container>
          <FabRS />
        </div>
        <MEFooter />
      </div>
    </>
  );
}

export default Servicios;
