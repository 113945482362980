/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  // DropdownToggle,
  // UncontrolledDropdown,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
//import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import HomeHeader from "components/Headers/HomeHeader";
import MEFooter from "components/Footers/MEFooter";
import 'react-tiny-fab/dist/styles.css';
import FabRS from "components/Fab/FabRS";

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <HomeHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">BIENVENIDOS!!!</h2>
                <h5 className="description">
                  Somos una empresa especializada en el rubro de motocicletas y en el servicio de post-venta. Maz Evolution es una empresa que nace con la finalidad de brindarle al cliente el sueño de adquirir la moto que necesita.
                </h5>
                <br />
                {/* <Button
                  className="btn-round"
                  color="info"
                  href="/nosotros-page"
                  onClick={(e) => e.preventDefault()}
                >
                  Ver Detalles
                </Button> */}
              </Col>
            </Row>
            {/* <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-cart-simple" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Productos</h4>
                    <p>
                      Información resumen de productos.
                    </p>
                    <UncontrolledDropdown >
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="info"
                        data-toggle="dropdown"
                        href="#pablo"
                        id="dropdownMenuButton"
                        onClick={(e) => e.preventDefault()}
                        role="button"
                      >
                        Productos
              </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="dropdownMenuButton"
                        className="dropdown-info"
                      >
                        <DropdownItem
                          href="/yamaha-page"
                        >
                          Motocicletas
                </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Electricbike
                </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Productos de Fuerza
                </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Accesorios
              </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-settings" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Servicios</h4>
                    <p>
                      Información resumen de Servicios.
                    </p>
                    <UncontrolledDropdown >
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="info"
                        data-toggle="dropdown"
                        href="#pablo"
                        id="dropdownMenuButton"
                        onClick={(e) => e.preventDefault()}
                        role="button"
                      >
                        Servicios
                      </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="dropdownMenuButton"
                        className="dropdown-info"
                      >
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Servicio Técnico
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Repuestos
                </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-album-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Eventos</h4>
                    <p>
                      Información resumen de eventos
                    </p>
                    <Button className="button" color="info" href="#pablo">
                      Ver más
                    </Button>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Contáctanos</h2>
            <Row>
              <Col md="12">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/logosME/logo.png")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <div >
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/logosME/letras.png").default}
                        width="300" height="400"
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Av. La Marina 1006 - Pueblo Libre, Perú</CardTitle>
                        <h6 className="card-category">Central : 6011570</h6>
                        <h6 className="card-category">Ventas : +51 999110356</h6>
                        <h6 className="card-category">Repuestos : +51 981579068</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                      Teamwork is so important that it is virtually impossible
                      for you to reach the heights of your capabilities or make
                      the money that you want without becoming very good at it.
                    </p> */}
                  </CardBody>
                  <CardFooter className="text-center">
                    <Col className="text-center" lg="12" md="12">
                      <Button
                        className="instagram-sharrre btn-just-icon"
                        color="instagram-bg"
                        href="https://instagram.com/mazevolution.pe?utm_medium=copy_link"
                        target="_blank"
                        id="tooltip3373767"
                      >
                        <i className="fa fa-instagram fa-2x" />
                      </Button>
                      <Button
                        className="linkedin-sharrre btn-just-icon  ml-2"
                        color="success"
                        target="_blank"
                        href="https://api.whatsapp.com/send?phone=51999110356&text=Hola"
                        id="tooltip840791273"
                      >
                        <i className="fa fa-whatsapp fa-3x" />
                      </Button>
                      <Button
                        className="btn-just-icon ml-2"
                        color="facebook-bg"
                        target="_blank"
                        href="https://www.facebook.com/mazevolutionperu/?rf=382754685167456"
                        id="tooltip68961360"
                      >
                        <i className="fa fa-facebook-square fa-3x" />
                      </Button>
                    </Col>
                    {/* <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button> */}
                  </CardFooter>
                </Card>
              </Col>
              {/* <Col className="ml-auto mr-auto" md="6">
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Nombre</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Nombre" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Mensaje</label>
                  <Input
                    placeholder="Cuéntanos en qué podemos ayudarte ..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Envíar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col> */}

            </Row>
            <FabRS />
          </Container>
        </div>
        <MEFooter />
      </div>
    </>
  );
}

export default HomePage;
