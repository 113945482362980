/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Eventos() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">BIENVENIDOS!!!</h2>
                <h5 className="description">
                  Somos una empresa especializada en el rubro de motocicletas y en el servicio de post-venta.  Nacemos con la finalidad de brindarle al cliente el sueño de adquirir la moto que necesita somos representantes de Motos Yamaha.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Ver Detalles
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-cart-simple" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Productos</h4>
                    <p>
                      Información resumen de productos.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      Ver más
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-settings" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Servicios</h4>
                    <p>
                      Información resumen de Servicios.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      Ver más
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-album-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Eventos</h4>
                    <p>
                      Información resumen de eventos
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      Ver más
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Contáctanos</h2>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/logosME/logo.png")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <div >
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/logosME/letras.png").default}
                        width="300" height="400"
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Av. La Marina 1006 - Pueblo Libre</CardTitle>
                        <h6 className="card-category">Central : 4616265 / 5923321 / 999110356 / 999453009</h6>
                      </div>
                    </a>
                    {/* <p className="card-description text-center">
                      Teamwork is so important that it is virtually impossible
                      for you to reach the heights of your capabilities or make
                      the money that you want without becoming very good at it.
                    </p> */}
                  </CardBody>
                  <CardFooter className="text-center">
                    <Col className="text-center" lg="12" md="12">
                      <Button
                        className="twitter-sharrre btn-round"
                        color="twitter-bg"
                        href="#pablo"
                        id="tooltip3373767"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" /> Twitter
                      </Button>
                      <Button
                        className="linkedin-sharrre btn-round  ml-2"
                        color="success"
                        href="#pablo"
                        id="tooltip840791273"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-whatsapp" /> Whatsapp
                      </Button>
                      <Button
                        className="facebook-sharrre btn-round ml-2"
                        color="facebook-bg"
                        href="#pablo"
                        id="tooltip68961360"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-facebook-square" /> Facebook
                      </Button>
                    </Col>
                    {/* <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Nombre</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Nombre" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Mensaje</label>
                  <Input
                    placeholder="Cuéntanos en qué podemos ayudarte ..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Envíar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

            </Row>
          </Container>
        </div>

      </div>
    </>
  );
}

export default Eventos;
