import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import MEFooter from "components/Footers/MEFooter";
import NosotrosHeader from "components/Headers/NosotrosHeader";
import FabRS from "components/Fab/FabRS";

function Nosotros() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
    <ExamplesNavbar />
      <NosotrosHeader />
      <div className="main">
        <div className="section text-center">
        <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">MAZ EVOLUTION</h2>
                <h5 className="description">
                Somos una empresa especializada en el rubro de motocicletas y en el servicio de post-venta. Maz Evolution es una empresa que nace con la finalidad de brindarle al cliente el sueño de adquirir la moto que necesita.
                </h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
        </Container>
        </div>
        <div  className="section section-dark text-center">
        <Container>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-spaceship" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Misión</h4>
                    <p>
                    Ser reconocidos por brindar a nuestros clientes la mayor cantidad de opciones de transporte  en dos ruedas
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Visión</h4>
                    <p>
                    Ofrecer  a nuestros clientes productos de alta calidad y tecnología de punta a través de la innovación permanente  en nuestra gama de productos
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-diamond" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Valores</h4>
                    <p>
                    Creemos en el mejoramiento continuo, y  apuntamos a generar productos y servicios de mayor valor en función a lograr la  satisfacción de nuestros clientes
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <FabRS />
        </div>
        <MEFooter />
      </div>
    </>
  );
}

export default Nosotros;
