/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";



// core components

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

function FabRS() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <div className="main">
            <Fab
              mainButtonStyles={{
                backgroundColor: '#9b59b6',
              }}
              //actionButtonStyles={actionButtonStyles}
              //style={style}
              icon={<i className="nc-icon nc-simple-add" />}
              //event={event}
              //alwaysShowTitle={true}
              //onClick={someFunctionForTheMainButton}
            >
            <a href="https://api.whatsapp.com/send?phone=51999110356&text=Hola" target="_blank">
              <Action style={{
                backgroundColor: '#27ae60',
              }}>
                <i className="fa fa-whatsapp fa-lg" />
              </Action>
            </a>
            <a target="_blank" href="https://www.facebook.com/mazevolutionperu/?rf=382754685167456">
              <Action style={{backgroundColor: '#3b5998',}}>
                <i className="fa fa-facebook fa-lg" />
              </Action>
            </a>
            <a target="_blank" href="https://instagram.com/mazevolution.pe?utm_medium=copy_link">
              <Action style={{backgroundColor: '#C13584',}}>
                <i className="fa fa-instagram fa-lg" />
              </Action>
            </a>
            </Fab>
      </div>
    </>
  );
}

export default FabRS;
