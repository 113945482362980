/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// core components

const items = [
  {
    src: require("../../assets/img/ebot/ebot0_1.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_2.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_3.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_4.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_5.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_6.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_7.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_8.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  },
  {
    src: require("../../assets/img/ebot/ebot0_9.png").default,
    altText: "Somewhere",
    caption: "Somewhere",
  }
];

function EbotCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>

      <Col className="ml-auto mr-auto" md="6">
        <Card className="page-carousel">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map((item) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <img src={item.src} alt={item.altText} />
                  <CarouselCaption
                    captionText={item.caption}
                    captionHeader=""
                  />
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </Card>
      </Col>


    </>
  );
}

export default EbotCarousel;
