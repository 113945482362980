
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import SsendaHeader from "components/Headers/SsendaHeader";
import MEFooter from "components/Footers/MEFooter";
import FabRS from "components/Fab/FabRS";
import SsendaCarousel from "./SsendaCarousel";

function SsendaPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("ssenda-page");
    return function cleanup() {
      document.body.classList.remove("ssenda-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <SsendaHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <h2 className="title">SSENDA</h2>
            <Row>
              <Col md="6">
                <img
                  alt="..."
                  src={require("../../assets/img/ssenda/ssenda2.png").default}
                  width="500" height="770"
                />
              </Col>
              <SsendaCarousel />

            </Row>
            <Button
              className="btn-round mr-1"
              color="danger"
              outline
              href="/Ssenda.pdf"
              type="button"
              download
            >
              Catálogo 1
            </Button>
            <Button
              className="btn-round mr-1"
              color="danger"
              outline
              href="/Ssenda_Custom-CafeRacer.pdf"
              type="button"
              download
            >
              Catálogo 2
            </Button>
          </Container>
          <FabRS />
        </div>
        {/* <div style={{
          backgroundImage:
            "url(" + require("assets/img/Fondo.png").default + ")",
        }} className="section section-dark text-center">
          <Container>
            <h2 className="title">Contáctanos</h2>
            <Row>
              <Col md="6">
              <img
                alt="..."
                src={require("../../assets/img/Yamaha/YamahaRecurso 1@4x.png").default}
                width="500" height="500"
              />
              </Col>
              <SectionCarousel />

            </Row>
          </Container>
          <FabRS />
        </div> */}
        <MEFooter />
      </div>
    </>
  );
}

export default SsendaPage;
