/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Row, Container } from "reactstrap";

function MEFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="/index"

                >
                  <img
                    alt="..."
                    src={require("assets/img/logosME/logoN.png").default}
                    width="60" height="40"
                  />
                </a>
              </li>
              <li>
                <a
                  data-placement="bottom"
                  href="https://www.facebook.com/mazevolutionperu/?rf=382754685167456"
                  target="_blank"
                  title="Like us on Facebook"
                >
                  <i className="fa fa-facebook-square fa-2x" />
                </a>
              </li>
              {/* <li>
                <a
                  data-placement="bottom"
                href="https://twitter.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fa fa-twitter" />
                </a>
              </li> */}
              <li>
                <a
                  data-placement="bottom"
                  href="https://api.whatsapp.com/send?phone=51999110356&text=Hola"
                  target="_blank"
                  title="Star on Whatsapp"
                >
                  <i className="fa fa-whatsapp fa-2x" />
                </a>
              </li>
              <li>
                <a
                  data-placement="bottom"
                  href="https://instagram.com/mazevolution.pe?utm_medium=copy_link"
                  target="_blank"
                  title="Follow us on Instagram"
                >
                  <i className="fa fa-instagram fa-2x" />
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span
              className="copyright"
            >
              <a
                className="copyright"
                href="https://gn3k.me/niuwer"
                target="_blank"
                title="GN3K Consultores"
              >
                © {new Date().getFullYear()} by
                    <img
                  alt="..."
                  src={require("../../assets/img/GN3K.gif").default}
                  width="100" height="50"
                />
              </a>
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default MEFooter;
